import type { Location } from '../entity/Location'
import LocalStorageProxy from '../shared/LocalStorageProxy'

export default class LocationSessionRepository {
  public static storeLocation(location: Location) {
    LocalStorageProxy.setItem(`location-session-value`, JSON.stringify(location))
    LocalStorageProxy.setItem(`location-session-time`, Date.now().toString())
  }

  public static storeCountry(location: Location) {
    LocalStorageProxy.setItem(`country-session-value`, JSON.stringify(location))
    LocalStorageProxy.setItem(`country-session-time`, Date.now().toString())
  }

  public static readLocation(): Location | null {
    if (!this.isLocationValid()) {
      console.warn('not valid')
      return null
    }

    const locStr = LocalStorageProxy.getItem(`location-session-value`) as string
    return JSON.parse(locStr) as Location
  }

  public static readCountry(): Location | null {
    if (!this.isCountryValid()) {
      console.warn('not valid')
      return null
    }

    const locStr = LocalStorageProxy.getItem(`country-session-value`) as string
    return JSON.parse(locStr) as Location
  }

  public static removeLocation(): void {
    LocalStorageProxy.removeItem(`location-session-value`)
    LocalStorageProxy.removeItem(`location-session-time`)
  }

  public static removeCountry(): void {
    LocalStorageProxy.removeItem(`country-session-value`)
    LocalStorageProxy.removeItem(`country-session-time`)
  }

  public static isLocationValid() {
    const locStr = LocalStorageProxy.getItem(`location-session-value`)
    if (!locStr) {
      return false
    }

    const timeStr = LocalStorageProxy.getItem(`location-session-time`)
    if (!timeStr) {
      return false
    }

    const time = Number(timeStr)
    const now = Date.now()

    const timeDiff = now - time

    return timeDiff < 1000 * 60 * 60
  }

  public static isCountryValid() {
    const locStr = LocalStorageProxy.getItem(`country-session-value`)
    if (!locStr) {
      return false
    }

    const timeStr = LocalStorageProxy.getItem(`country-session-time`)
    if (!timeStr) {
      return false
    }

    const time = Number(timeStr)
    const now = Date.now()

    const timeDiff = now - time

    return timeDiff < 1000 * 60 * 60 * 24
  }
}
