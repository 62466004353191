import EventBus from './event-bus'

import ServiceNavigationStack from '../ts/repository/ServiceNavigationStackRepository'
import { translateSilent } from '../ts/util/translate'
import { dateToYYYYMMDD } from '../ts/util/date'
import { joinPaths } from '../ts/util/string'

import config from '../ts/server-config'

import type {
  ConnectifCart,
  ConnectifEntity,
  ConnectifEvent,
  ConnectifEventPack,
  ConnectifParams,
  ConnectifProduct
} from '../components/singleton/GeneralConnectif.vue'

import type { VoucherPurchase } from '../ts/entity/VoucherPurchase'
import { Service } from '../ts/entity/Service'
import type { Spa } from '../ts/entity/Spa'

export default class ConnectifEventBus {
  static on(func: any): number {
    return EventBus.on('connectif-event', func)
  }

  static off(id: number) {
    return EventBus.off('connectif-event', id)
  }

  static send(name: string, meta: ConnectifEventPack) {
    EventBus.emit('connectif-event', name, meta)
  }

  static mapVoucherPurchaseToParams(purchase: VoucherPurchase, domain: string): ConnectifParams {
    const params: ConnectifParams = {}

    const [voucher] = purchase.vouchers

    let totalQuantity = 0
    voucher.items.forEach((item) => {
      totalQuantity += item.units
    })

    const totalPrice = purchase.pricing.final.amount / 100
    const purchaseDate = dateToYYYYMMDD(purchase.status.startedOn)

    const products: ConnectifCart['products'] = voucher.items.map((item): ConnectifProduct => {
      const discountCents = purchase.pricing.base.amount - purchase.pricing.final.amount
      const discountPercent = discountCents > 0 ? purchase.pricing.base.amount / discountCents : 0

      const service = ServiceNavigationStack.getByUuid(item.uuid)
      const serviceURI = service ? service.uri : config.runtime.domain

      return {
        quantity: item.units,
        price: purchase.pricing.final.amount / 100,
        productDetailUrl: joinPaths(domain, serviceURI),
        productId: item.uuid,
        name: item.name,
        description: '',
        imageUrl: '',
        unitPrice: totalPrice / totalQuantity,
        availability: 'InStock',
        categories: [],
        tags: [],
        brand: purchase.spa.name,

        // reviewCount: 0,
        // rating: 0,
        // ratingCount: 0,
        // thumbnailUrl: '',
        // priority: 0,

        unitPriceOriginal: purchase.pricing.base.amount / 100,
        unitPriceWithoutVAT: purchase.pricing.base.amount / 100,
        discountedAmount: discountCents / 100,
        discountedPercentage: discountPercent,
      }
    })

    params.cart = {
      cartId: purchase.uuid,
      totalQuantity,
      totalPrice,
      products,

      purchaseDate,
    }

    if (purchase.buyer.email) {
      const subscriptionStatus = purchase.buyer.allowNotifications ? 'subscribed' : 'unsubscribed'

      params.entityInfo = {
        primaryKey: purchase.buyer.email,
        _name: purchase.buyer.name,
        _surname: purchase.buyer.surname,
        _newsletterSubscriptionStatus: subscriptionStatus,
      }
    }

    return params
  }

  static mapServiceToProduct(service: Service, spa: Spa, domain: string, date: Date): ConnectifProduct {
    const basePriceCentsAmount = Service.getBasePriceAmount(service)
    const basePriceAmount = basePriceCentsAmount / 100

    const discountedPriceCentsAmount = Service.getDiscountedPriceAmount(service, date)
    const discountedPriceAmount = discountedPriceCentsAmount / 100

    const discountPercent = Service.getDiscountPercent(service, date)

    const translation = translateSilent(service.texts)

    const name = translation ? translation.name : 'Servicio sin nombre'
    const description = translation ? translation.description : ''

    const product: ConnectifProduct = {
      quantity: 1,
      price: basePriceAmount,
      productDetailUrl: joinPaths(domain, service.uri),
      productId: service.uuid,
      name,
      description,
      imageUrl: '',
      unitPrice: basePriceAmount,
      availability: 'InStock',
      categories: [],
      tags: [],
      brand: spa.name,

      // reviewCount: 0,
      // rating: 0,
      // ratingCount: 0,
      // thumbnailUrl: '',
      // priority: 0,

      unitPriceOriginal: basePriceAmount,
      unitPriceWithoutVAT: basePriceAmount,
      discountedAmount: discountedPriceAmount,
      discountedPercentage: discountPercent,
    }

    return product
  }

  static sendProductVisitedService(service: Service, spa: Spa, date: Date) {
    const runtimeConfig = useRuntimeConfig()
    const product = this.mapServiceToProduct(service, spa, runtimeConfig.public.domain, date)

    const pack: ConnectifEventPack = {
      events: [
        { type: 'product-visited', product, }
      ],
      param: {},
    }

    this.send('product_visited', pack)
  }

  static sendAddToCartVoucher(purchase: VoucherPurchase) {
    const runtimeConfig = useRuntimeConfig()
    const param: ConnectifParams = this.mapVoucherPurchaseToParams(purchase, runtimeConfig.public.domain)
    this.send('add_to_cart', { events: [], param })
  }

  static sendAddshippingInfoVoucher(purchase: VoucherPurchase) {
    const runtimeConfig = useRuntimeConfig()
    const param: ConnectifParams = this.mapVoucherPurchaseToParams(purchase, runtimeConfig.public.domain)

    const events: Array<ConnectifEvent> = []
    if (purchase.buyer.allowNotifications) {
      const entityInfo = param.entityInfo as ConnectifEntity
      events.push({ type: 'newsletter-subscribe', email: entityInfo.primaryKey })
    }

    this.send('add_shipping_info', { events, param })
  }

  static sendPurchaseVoucher(purchase: VoucherPurchase) {
    const runtimeConfig = useRuntimeConfig()
    const param: ConnectifParams = this.mapVoucherPurchaseToParams(purchase, runtimeConfig.public.domain)
    const cart = param.cart as ConnectifCart

    const pack: ConnectifEventPack = {
      events: [
        {
          type: 'purchase',
          purchase: cart,
          purchaseId: cart.purchaseId,
          purchaseDate: cart.purchaseDate,
          paymentMethod: 'Credit Card'
        },
      ],
      param,
    }

    this.send('purchase', pack)
  }
}
