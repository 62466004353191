import { addLanguageToURL } from '~/core/ts/util/translate'

import { type MenuItem } from '~/core/ts/entity/MenuItem'
import { supportedLangs, getCurrentURLLanguage, getCurrentLanguage } from '../../ts/lang-config'

export function useLangSelector() {
  const config = useRuntimeConfig()
  const envLang: string = config.public.language

  const currentURLLang = getCurrentURLLanguage()
  const currentLang = getCurrentLanguage()

  function addCurrentOrOverrideLanguageToURL(url: string, overrideLang = '') {
    const lang = overrideLang ? overrideLang : currentLang
    return addLanguageToURL(url, lang)
  }

  const isActive = true

  function generateLangMenuItemsFromURL(url: string): Array<MenuItem> {
    if (!isActive) {
      return []
    }

    return [
      {
        title: currentURLLang ? currentURLLang.toLocaleUpperCase() : envLang.toLocaleUpperCase(),
        url: '',
        metadata: {
          imgByKey: currentLang,
        },
        children: supportedLangs
          .map((lang) => {
            return {
              title: lang.toLocaleUpperCase(),
              url: addCurrentOrOverrideLanguageToURL(url, lang),
              lang,
              targetBlank: false,
              metadata: {
                imgByKey: lang,
              },
            }
          })
          .sort((a, b) => a.title.localeCompare(b.title))
      }
    ]
  }

  function generateLangMenuItems(urlByLangDict: Record<string, string>): Array<MenuItem> {
    if (!isActive) {
      return []
    }

    return [
      {
        title: currentURLLang ? currentURLLang.toLocaleUpperCase() : envLang.toLocaleUpperCase(),
        url: '',
        metadata: {
          imgByKey: currentLang,
        },
        children: Object.entries(urlByLangDict)
          .map(([lang, url]) => {
            return {
              title: lang.toLocaleUpperCase(),
              url: addCurrentOrOverrideLanguageToURL(url, lang),
              lang,
              targetBlank: false,
              metadata: {
                imgByKey: lang,
              },
            }
          })
          .sort((a, b) => a.title.localeCompare(b.title))
      }
    ]
  }

  return {
    currentLang,
    currentURLLang,

    generateLangMenuItemsFromURL,
    generateLangMenuItems,
    addCurrentOrOverrideLanguageToURL,
  }
}
