<template>
  <div />
</template>

<script lang='ts'>
import ConnectifEventBus from '~/core/events/connectif'

export type ConnectifProduct = {
  quantity: number,
  price: number,
  productDetailUrl: string,
  productId: string,
  name: string,
  description: string,
  imageUrl: string,
  unitPrice: number,
  availability: string,
  categories: Array<string>,
  tags: Array<string>,
  brand: string,

  reviewCount?: number,
  rating?: number,
  ratingCount?: number,

  thumbnailUrl?: string,

  priority?: number,
  unitPriceOriginal: number,
  unitPriceWithoutVAT: number,
  discountedAmount: number,
  discountedPercentage: number,
  publishedAt?: string, // '2020-05-20T15:24:20Z',
  // customField1: '',
}

export type ConnectifCart = {
  cartId: string,
  totalQuantity: number,
  totalPrice: number,
  products: Array<ConnectifProduct>,

  // Extra para el evento 'purchase'
  purchaseId?: string,
  purchaseDate?: string,
  paymentMethod?: string,
}

export type ConnectifEntity = {
  primaryKey: string, // Email
  _name: string,
  _surname: string,
  _newsletterSubscriptionStatus: string,
}

export type ConnectifParams = {
  entityInfo?: ConnectifEntity,
  cart?: ConnectifCart,
}

export type ConnectifEvent = {
  type: string,
  email?: string, // newsletter
  product?: ConnectifProduct,

  // purchase event
  purchase?: ConnectifCart,
  purchaseId?: string,
  purchaseDate?: string,
  paymentMethod?: 'Credit Card'
}

export type ConnectifEventPack = {
  events: Array<ConnectifEvent>,
  param: ConnectifParams,
}

export type ConnectifWindow = Window & {
  connectifConfiguration: object,
  initializeConnectif: (a: object) => void,

  connectif: {
    managed: {
      sendEvents: (events: Array<ConnectifEvent>, params?: ConnectifParams) => void,
    },
  },
}

export default defineNuxtComponent({
  setup() {
    const runtimeConf = useRuntimeConfig()

    const isLoaded = ref(false)
    const isInitialized = ref(false)
    const storedEvents: Array<{name: string, pack: ConnectifEventPack}> = []

    function installConnectif() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      !function(e){function t(){if(!e.querySelector('#__cn_client_script_194ac43b-95e5-46f2-a07d-8608a2fe389f')){var t=e.createElement('script');t.setAttribute('src','https://cdn.connectif.cloud/eu7/client-script/194ac43b-95e5-46f2-a07d-8608a2fe389f'),e.body.appendChild(t)}}'complete'===e.readyState||'interactive'===e.readyState?t():e.addEventListener('DOMContentLoaded',t)}(document)
    }

    function tryToSendEvent(name: string, pack: ConnectifEventPack) {
      const win = window as unknown as ConnectifWindow
      if (!isInitialized.value) {
        // Si no está iniciado, almacenamos los eventos para más tarde.
        storedEvents.push({name, pack})
        return
      }

      win.connectif.managed.sendEvents(pack.events, pack.param)
    }

    onMounted(() => {
      if (runtimeConf.public.isDev) {
        return
      }

      ConnectifEventBus.on(tryToSendEvent)

      document.addEventListener('connectif.managed.initialized', function onConnectifInitialized() {
        if (!isLoaded.value) {
          return
        }

        isInitialized.value = true

        // Enviamos los eventos que se almacenaron porque Connectif no se había iniciado.
        storedEvents.forEach(({name, pack}) => tryToSendEvent(name, pack))
        storedEvents.length = 0

        const win = window as unknown as ConnectifWindow
        win.connectif.managed.sendEvents([{type: 'page-visit'}], {})
      })

      document.addEventListener('connectif.loaded', function onConnectifLoaded() {
        isLoaded.value = true

        const win = window as unknown as ConnectifWindow
        win.initializeConnectif(win.connectifConfiguration)
      })

      installConnectif()
    })

    return {}
  }
})
</script>
