export type PictureDTO = {
  order: number;
  entity_uuid: string;
  relation_type: string;
  file: {
    uuid: string;
    name: string;
    url: string;
  };
};

export class Picture {
  constructor(
    public order: number,
    public entityUUID: string,
    public relationType: string,
    public file: {
      uuid: string,
      url: string,
    },
  ) {
  }

  static from(dto: PictureDTO): Picture {
    const { uuid, url } = dto.file

    return new Picture(
      dto.order,
      dto.entity_uuid,
      dto.relation_type,
      { uuid, url }
    )
  }

  static getDefaultImage(): Picture {
    const entityUUID = '11111111-0000-0000-0000-111111111111'
    const url = 'https://marketplace-assets.s3.eu-west-3.amazonaws.com/2020/10/45656-pre-barrosapalace-spa4206.jpg'

    const file = {
      url: url,
      uuid: '11111111-0000-0000-0000-111111111111',
    }

    return new Picture(
      1,
      entityUUID,
      'images',
      file,
    )
  }
}
