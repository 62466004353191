export default class CookiesRepository {
  public static getCookiesAsText(cname: string): string {
    const name = cname + '='
    const decodedCookie = decodeURIComponent(document.cookie)
    const cookiesList = decodedCookie.split(';')

    for (let i = 0; i < cookiesList.length; i++) {
      let candidate = cookiesList[i]
      while (candidate.charAt(0) == ' ') {
        candidate = candidate.substring(1)
      }
      if (candidate.indexOf(name) == 0) {
        return candidate.substring(name.length, candidate.length)
      }
    }
    return ''
  }

  public static getCookiesAsObject(cname: string): Record<string, any> {
    const cookieValue = this.getCookiesAsText(cname) || '{}'
    return JSON.parse(cookieValue) as Record<string, any>
  }

  public static getCookiesAsArray(cname: string): Array<any> {
    const cookieValue = this.getCookiesAsText(cname) || '[]'
    return JSON.parse(cookieValue) as Array<any>
  }

  public static addCookie(cname: string, value: string | boolean | number) {
    document.cookie = `${cname}=${value}`
  }

  public static pushCookie(cname: string, key: string, value: string | boolean | number) {
    const obj = this.getCookiesAsObject(cname)
    obj[key] = value
    document.cookie = `${cname}=${JSON.stringify(obj)}`
  }

  private static attempts = 0
  public static addCookieConsentCallback(callback: () => void) {
    const addConsentListener = () => {
      const win = window as any

      // Se configura desde scripts/klaroConfig.js
      if (!win.consentWatcher) {
        this.attempts++
        console.log(`Consent watcher failure. Trying again...`)

        if (this.attempts > 64) {
          console.warn(`Consent watcher failure. Max attempts reached.`)
          return
        }
        setTimeout(addConsentListener, 100)
        return
      }

      win.consentWatcher.listeners.push(callback)
    }

    // Usamos setTimeout para asegurar que el consentWatcher esté configurado.
    setTimeout(addConsentListener, 100)
  }
}
