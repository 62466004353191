<template>
  <div>
    <div v-if="isDev">
      <div style="background-color: yellow;">
        <span style="color: red; font-weight: bold;">DEV: </span>
        <span style="color: black;">Sólo visible en modo desarrollo con en el servidor de DEV.</span>
      </div>
      <pre>{{ redirection }}</pre>
      <span
        style="text-decoration: underline; padding: 8px; color: blue;"
      >
        redirection.to
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { useFetchProxy } from '~/core/composable/shared/useFetchProxy'
import { Redirection } from '~/core/ts/entity/Redirection'
import { joinPaths } from '~/core/ts/util/string'

definePageMeta({
  layout: 'blank',
})

export default defineNuxtComponent({
  async setup() {
    const route = useRoute()
    const runtime = useRuntimeConfig()

    onMounted(() => {
      const meta = document.getElementById('redirector')
      if (meta) {
        var redirectionContent = meta.getAttribute('content')
        if (redirectionContent) {
          var url = redirectionContent.split('url=')[1] || window.location.href
          if (location.search.length) { window.stop() }
          window.location.href = url + location.search
        }
      }
    })

    const redirection = await useFetchProxy<Redirection>(
      '/api/redirections/get-redirection-from-uri',
      { method: 'post', body: { uri: route.path }
    })

    const domain = runtime.public.domain
    const finalurl = joinPaths(domain, redirection.to)

    const isDev = runtime.public.isDev
    useHead(
      isDev
        ? { meta: [] }
        : { meta: [{ id: 'redirector', 'http-equiv': 'refresh', content: `0; url=${finalurl}` }] }
    )

    return {
      isDev,
      redirection,
    }
  }
})
</script>
