import { GetAwayAvailability, GetAwayRate, type GetAwayAvailabilityDTO } from "./GetAwayAvailability"
import { GetAwayPurchaseBuyer } from "./GetAwayPurchaseBuyer"

export type GetAwayPurchaseDTO = {
  uuid: string,
  uuid_revision: string,
  status: {
    type: string,
    times: {
      started_on: number,
      confirmed_on: number | null,
      canceled_on: number | null,
      abandoned_on: number | null,
      failed_on: number | null,
      confirming_on: number | null,
    }
  },
  lang_code?: string,
  locators: {
    purchase_locator: string,
    voucher_code: string,
    hotel_locator: string,
  },

  package_availability: GetAwayAvailabilityDTO,

  buyer: {
    name: string,
    surname: string,
    phone: string,
    email: string,
    comment: string,
    allow_notifications: boolean,
    localization: {
      address: string,
      country: string,
      city: string,
      postal_code: string,
    },
  },
  payments: Array<any>,
  conditions: {
    hotel_book_conditions: string,
    spa_book_conditions: string,
  },

  metadata: any,
}

export type GetAwayPurchaseUpdateDTO = {
  uuid: string,
  uuid_revision: string,
  package_availability: {
    package_uuid: string,
    from: number,
    to: number,
    num_pax: number,
  },
  buyer: GetAwayPurchaseDTO['buyer'],
  lang_code?: string,
  locators: {
    hotel_locator: string,
    purchase_locator: string,
    voucher_code: string,
  },
  payments: Array<any>,
  status: {
    type: string,
  },
  metadata: string,
}

export class GetAwayPurchase {
  constructor(
    public uuid: string,
    public uuidRevision: string,
    public status: string,

    public langCode: string,

    public locators: {
      purchase: string,
      voucher: string,
      hotel: string,
    },

    public getAwayAvailability: GetAwayAvailability,
    public buyer: GetAwayPurchaseBuyer,

    public payments: Array<any>,
    public conditions: {
      hotel: string,
      spa: string,
    },
    public metadata: any,
  ) {
  }

  public static from(dto: GetAwayPurchaseDTO, runtimeLang: string): GetAwayPurchase {
    const locators = {
      purchase: dto.locators.purchase_locator,
      voucher: dto.locators.voucher_code,
      hotel: dto.locators.hotel_locator,
    }

    const availability = GetAwayAvailability.from(dto.package_availability, runtimeLang)

    const buyer = GetAwayPurchaseBuyer.fromGetAwayBuyer(dto.buyer)

    const conditions = {
      hotel: dto.conditions.hotel_book_conditions,
      spa: dto.conditions.spa_book_conditions,
    }

    const langCode = dto.lang_code ? dto.lang_code : 'es'

    return new GetAwayPurchase(
      dto.uuid,
      dto.uuid_revision,
      dto.status.type,
      langCode,
      locators,

      availability,
      buyer,

      dto.payments,
      conditions,
      dto.metadata,
    )
  }

  public static toUpdateDTO(purchase: GetAwayPurchase): GetAwayPurchaseUpdateDTO {
    return {
      uuid: purchase.uuid,
      uuid_revision: purchase.uuidRevision,
      package_availability: {
        package_uuid: purchase.getAwayAvailability.getAwayUUID,
        from: purchase.getAwayAvailability.from,
        to: purchase.getAwayAvailability.to,
        num_pax: purchase.getAwayAvailability.numPax,
      },
      buyer: GetAwayPurchaseBuyer.toGetAwayBuyer(purchase.buyer),
      lang_code: purchase.langCode,
      locators: {
        hotel_locator: purchase.locators.hotel,
        purchase_locator: purchase.locators.purchase,
        voucher_code: purchase.locators.voucher,
      },
      payments: purchase.payments,
      status: {
        type: purchase.status,
      },
      metadata: purchase.metadata,
    }
  }

  public static isConfirmed(self: GetAwayPurchase): boolean {
    return self.status === 'confirmed'
  }

  public static isConfirming(self: GetAwayPurchase): boolean {
    return self.status === 'confirming'
  }

  public static isFailed(self: GetAwayPurchase): boolean {
    return self.status === 'failed'
  }

  public static getSelectedRate(self: GetAwayPurchase): GetAwayRate | null {
    if (!self.getAwayAvailability.rates.length) {
      return null
    }

    return self.getAwayAvailability.rates[0]
  }
}
