import { supportedLangs } from '../lang-config'
import config from '../server-config'
import { capitalizeAllLargeWords, capitalizeFirstWord } from '../util/string'

export interface TagDTO {
  uuid: string,
  uuid_parents?: Array<string>,
  title: string,
  slug: string,
  description: string,
  translations?: Array<{
    lang_code: string,
    title: string,
    description: string,
    slug: string,
  }>,
}

export type TagText = {
  title: string,
  description: string,
  slug: string,
}

export class Tag {
  constructor(
    public uuid: string,
    public uuidParents: Array<string>,
    public title: string,
    public slug: string,
    public description: string,
    public texts: Record<string, TagText>,
  ) {

  }

  static from(dto: TagDTO): Tag
  {
    let title = capitalizeAllLargeWords(dto.title)
    let description = capitalizeFirstWord(dto.description)
    let slug = dto.slug

    const texts: Record<string, TagText> = {
      'es': {
        title,
        description,
        slug: dto.slug,
      }
    }

    if (dto.translations) {
      dto.translations.forEach((trans) => {
        if (!supportedLangs.includes(trans.lang_code)) {
          return
        }

        texts[trans.lang_code] = {
          title: capitalizeAllLargeWords(trans.title),
          description: capitalizeFirstWord(trans.description),
          slug: trans.slug,
        }

        if (trans.lang_code === config.runtime.language) {
          title = capitalizeAllLargeWords(trans.title)
          description = capitalizeFirstWord(trans.description)
          slug = trans.slug
        }
      })
    }

    const uuidParents: Array<string> = dto.uuid_parents ? dto.uuid_parents : []

    return new Tag(
      dto.uuid,
      uuidParents,
      title,
      slug,
      description,
      texts,
    )
  }
}
