<template>
  <div />
</template>

<script lang="ts">
import { useLangSelector } from '~/core/composable/shared/useLangSelector'

// ----------------------------------------------------------
function makeKlaroConfig(currentLang: string) {
  // Esta cosa está hackeada a más no poder. Abajo hay 'NOTE' detallando cada burrada :D
  // También hay estilos que modifican esto en app.vue

  const win: any = window

  win.language = currentLang

  const configureBtn = '<div style="padding: 12px 4px;"><a style="cursor: pointer;" onClick="return customizeCookies();" class="customize-cookies">Configurar</a></div>'

  win.klaroConfig = {
    elementID: 'klaro',
    cookieName: 'klaro',
    cookieExpiresAfterDays: '365',
    privacyPolicy: '/politica-de-cookies/',
    htmlTexts: true,
    mustConsent: true,
    translations: {
      es: {
        consentModal: {
          description: 'Aquí puedes ver y personalizar la información que se recolecta acerca de ti en este portal web.',
          title: 'Información que se recolecta',
          privacyPolicy: {
            text: `Para saber más, por favor lee nuestra {privacyPolicy}.<br/>` + configureBtn,
            name: 'Política de privacidad',
          },
        },
        consentNotice: {
          changeDescription: 'Hubieron cambios desde tu última visita, revisa tus consentimientos.',
          description: `Utilizamos cookies propias y de terceros con fines técnicos, analíticos, para mejora de productos y servicios, para mostrarte publicidad personalizada en base a un perfil elaborado a partir de tus hábitos de navegación y para la medición del rendimiento de anuncios y contenidos. Puedes aceptar todas las cookies pulsando en “Acepto” o configurar su uso pulsando en "Configurar".`,
          learnMore: 'Configurar',
        },
        privacyPolicy: {
          text: `Para saber más, por favor lee nuestra {privacyPolicy}.<br/>` + configureBtn,
          name: 'Política de privacidad',
        },
        poweredBy: ' ',
        ok: 'Acepto',
        decline: 'Rechazo',
        save: 'Aplicar',
        close: 'Cerrar',
        services: {
          purpose: 'Propósito',
          disableAll: {
            title: 'Invertir todos los consentimientos',
            description: 'Usa este interruptor para habilitar/deshabilitar tu consentimiento a todas las aplicaciones.',
          },
          optOut: {
            title: '(opt-out)',
            description: 'Esta aplicación se carga por defecto (pero puede excluirla)',
          },
          required: {
            title: '(siempre necesaria)',
            description: 'Esta aplicación siempre es necesaria',
          },
          purposes: 'Propósitos',
        },
        purposes: {
          analytics: 'Análisis',
          security: 'Seguridad',
          advertising: 'Publicidad',
          marketing: 'Marketing',
          styling: 'Usabilidad',
          statistics: 'Estadísticas',
          functionality: 'Funcionalidad',
          other: 'Otros',
        },
      },
      pt: {
        consentModal: {
          description: 'Aqui você pode ver e personalizar as informações que são coletadas sobre você neste portal web.',
          title: 'Informações que são coletadas',
          privacyPolicy: {
            text: `Para saber mais, por favor leia nossa {privacyPolicy}.<br/>` + configureBtn,
            name: 'Política de privacidade',
          },
        },
        consentNotice: {
          changeDescription: 'Houve mudanças desde sua última visita, revise seus consentimentos.',
          description: `Utilizamos cookies próprios e de terceiros com fins técnicos, analíticos, para melhoria de produtos e serviços, para mostrar publicidade personalizada com base em um perfil elaborado a partir de seus hábitos de navegação e para a medição do desempenho de anúncios e conteúdos. Você pode aceitar todos os cookies clicando em “Aceito” ou configurar seu uso clicando em "Configurar".`,
          learnMore: 'Configurar',
        },
        privacyPolicy: {
          text: `Para saber mais, por favor leia nossa {privacyPolicy}.<br/>` + configureBtn,
          name: 'Política de privacidade',
        },
        poweredBy: ' ',
        ok: 'Aceito',
        decline: 'Rejeito',
        save: 'Aplicar',
        close: 'Fechar',
        services: {
          purpose: 'Propósito',
          disableAll: {
            title: 'Reverter todos os consentimentos',
            description: 'Use este interruptor para habilitar/desabilitar seu consentimento a todos os aplicativos.',
          },
          optOut: {
            title: '(opt-out)',
            description: 'Este aplicativo é carregado por padrão (mas você pode excluí-lo)',
          },
          required: {
            title: '(sempre necessário)',
            description: 'Este aplicativo é sempre necessário',
          },
          purposes: 'Propósitos',
        },
        purposes: {
          analytics: 'Análise',
          security: 'Segurança',
          advertising: 'Publicidade',
          marketing: 'Marketing',
          styling: 'Usabilidade',
          statistics: 'Estatísticas',
          functionality: 'Funcionalidade',
          other: 'Outros',
        },
      }
    },
    services: [
      {
        name: 'klaro-consent-manager',
        title: 'Klaro Consent Manager',
        translations: {
          es: {
            description: 'Permite recordar al navegador web los consentimientos que aquí estás expresando, para futuras visitas.',
          },
          pt: {
            description: 'Permite que o navegador da Web se lembre dos consentimentos expressos aqui, para futuras visitas.'
          },
        },
        description:
          '',
        required: true,
        default: true,
        optOut: false,
        onlyOnce: false,
        purposes: ['functionality'],
      },
      {
        name: 'spalopia-booking-engine',
        title: 'Motor de reservas de Spalopia',
        translations: {
          es: {
            description: 'Control de sesión de usuario. La información personal del usuario nunca se guarda en el navegador web sino en el servidor.',
          },
          pt: {
            description: 'Controlo da sessão do utilizador. As informações pessoais do utilizador nunca são armazenadas no navegador Web, mas sim no servidor.'
          },
        },
        required: true,
        default: true,
        optOut: false,
        onlyOnce: false,
        purposes: ['functionality'],
      },
      {
        name: 'google-analytics',
        title: 'Google Analytics',
        translations: {
          es: {
            description: 'Herramienta utilizada para obtener datos estadísticos del tráfico web y de nuestros usuarios.',
          },
          pt: {
            description: 'Ferramenta utilizada para obter dados estatísticos sobre o tráfego na Web e os nossos utilizadores.'
          },
        },
        required: false,
        default: false,
        optOut: false,
        onlyOnce: true,
        purposes: ['analytics'],
      },
      {
        name: 'connectif',
        title: 'Connectif',
        translations: {
          es: {
            description: 'Herramienta utilizada para obtener datos estadísticos del tráfico web.',
          },
          pt: {
            description: 'Ferramenta utilizada para obter dados estatísticos sobre o tráfego na Web.'
          },
        },
        required: false,
        default: false,
        optOut: false,
        onlyOnce: true,
        purposes: ['analytics'],
      },
      {
        name: 'google-tag-manager',
        title: 'Google Tag Manager',
        translations: {
          es: {
            description: 'Herramienta utilizada para realizar seguimiento y obtener datos estadísticos de la interacción de los usuarios con nuestra web con el objetivo de mejorar nuestros servicios.',
          },
          pt: {
            description: 'Ferramenta utilizada para seguir e obter dados estatísticos sobre a interação dos utilizadores com o nosso sítio Web, a fim de melhorar os nossos serviços.',
          },
        },
        required: false,
        default: false,
        optOut: false,
        onlyOnce: true,
        purposes: ['styling'],
      },
      {
        name: 'facebook',
        title: 'Facebook',
        translations: {
          es: {
            description: 'Permite obtener datos estadísticos y establecer un perfil de usuario con objetivo de mostrar anuncios personalizados.',
          },
          pt: {
            description: 'Permite obter dados estatísticos e estabelecer um perfil de utilizador para apresentar anúncios personalizados.',
          },
        },
        required: false,
        default: false,
        optOut: false,
        onlyOnce: true,
        purposes: ['marketing'],
      },
      {
        name: 'fullstory',
        title: 'Fullstory',
        translations: {
          es: {
            description: 'Instala cookies que permiten monitorizar el comportamiento del usuario y, con ello, mejorar la usabilidad de la página web.',
          },
          pt: {
            description: 'Instala cookies para monitorizar o comportamento do utilizador e, assim, melhorar a usabilidade do sítio Web.',
          },
        },
        required: false,
        default: false,
        optOut: false,
        onlyOnce: true,
        purposes: ['analytics'],
      },
      {
        name: 'manychat',
        title: 'ManyChat',
        translations: {
          es: {
            description: 'Utiliza cookies para poder interactuar con usuarios a través de un formulario (ventana emergente) y resolver sus peticiones.',
          },
          pt: {
            description: 'Utiliza cookies para poder interagir com os utilizadores através de um formulário (janela pop-up) e resolver os seus pedidos.',
          },
        },
        required: false,
        default: false,
        optOut: false,
        onlyOnce: false,
        purposes: ['styling'],
      },
    ],
    acceptAll: true,
  }

  // NOTE: Para ver los estilos modificados de Klaro, ver el archivo app.vue
  win.customizeCookies = function () {
    const cmbody = document.getElementsByClassName('cm-body').item(0)
    if (cmbody) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      cmbody.style.display = 'block'
    }

    const cmkaro = document.getElementsByClassName('cm-karo').item(0)
    if (cmkaro) {
      cmkaro.classList.toggle('customize')
    }
  }

  // Quita el título H1 y lo sustituye por un <P>. Esto se hace por alguna historia de SEO.
  const swapTitle = () => {
    const title = document.querySelector('h1.title')

    if (title) {
      const existingP = document.getElementById('klaro-title-sub')
      if (existingP) {
        existingP.remove()
      }

      const holder = title.parentElement

      // Add P
      const p = document.createElement('p')
      p.setAttribute('id', 'klaro-title-sub')
      p.innerHTML = title.innerHTML
      p.style.cssText += 'font-size: 24px; margin-bottom: 8px;'//font-weight: bold;'
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      holder.prepend(p)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      holder.setAttribute('class', '')
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      holder.style.cssText = 'padding-left: 12px;'

      // HACK: No permitimos que se agreguen otros hijos al contenedor.
      //       Cuando se hace clic fuera de la caja de Klaro, se vuelve a renderizar,
      //       y se vuelven a añadir los elementos del cuadro usando appendChild.
      //       De esta manera, bloqueamos este comportamiento para mantener el P como título.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      holder.appendChild = () => { console.log('KaroConfig::swapTitle::appendChild -> void') }

      title.remove()
    } else {
      setTimeout(swapTitle, 250)
    }
  }

  win.addEventListener('load', function () {
    swapTitle()

    const consentWatcher = {
      listeners: [],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      update(obj, name, data) {
        if (name === 'saveConsents') {
          // location.reload()
          this.listeners.forEach((callback) => {
            if (typeof callback === 'function') {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              callback(data)
            }
          })
        }
      },
    }

    // Usado desde CookiesRepository
    win.consentWatcher = consentWatcher

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!win.klaro) return

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const consentManager = win.klaro.getManager()
    consentManager.watch(consentWatcher)
  })
}

export default defineNuxtComponent({
  setup() {
    const {
      currentLang
    } = useLangSelector()

    onMounted(() => {
      makeKlaroConfig(currentLang)

      const s = document.createElement('script')
      s.setAttribute('src', 'https://cdn.spalopia.app/static-webs/js/klaro/v0.7.4/klaro.js')
      document.body.appendChild(s)
    })

    return {}
  },
})
</script>
