import { Service } from '../entity/Service'
import LocalStorageProxy from '../shared/LocalStorageProxy'

export default class ServiceNavigationStack {
  public static push(service: Service) {
    if (this.getByUuid(service.uuid)) {
      return
    }

    const storedStack = LocalStorageProxy.getItem('service-navigation-stack') || ''

    const stack = storedStack.split(',').filter((a) => a.length)

    stack.push(service.uuid)
    if (stack.length > 8) {
      const removed = stack.shift()
      LocalStorageProxy.removeItem(`service-navigation-stack-${removed}`)
    }

    const stackStr = stack.join(',')
    LocalStorageProxy.setItem('service-navigation-stack', stackStr)

    const json = JSON.stringify(service)
    LocalStorageProxy.setItem(`service-navigation-stack-${service.uuid}`, json)
  }

  public static getByUuid(uuid: Service['uuid']): Service | null {
    const json = LocalStorageProxy.getItem(`service-navigation-stack-${uuid}`)
    if (!json) {
      return null
    }

    return JSON.parse(json)
  }
}
