import { useContext } from './useContext'

import LocationSessionRepository from '~/core/ts/repository/LocationSessionRepository'

import { Location } from '~/core/ts/entity/Location'
import { useSelectorToQueryParam } from './useSelectorToQueryParam'

export function useSessionLocation(locationCollection: Array<Location> = []) {
  const context = useContext()
  const {
    optionSelected,
    pushQueryParam,
    runOnMounted,
  } = useSelectorToQueryParam({
    label: 'country',
    defaultValue: ''
  }, false)

  const locationsByUUID: Record<string, Location> = {}
  const locations: Array<Location> = locationCollection
  locations.forEach((loc) => { locationsByUUID[loc.uuid] = loc })

  const storedLocationInternal = useState<Location | null>('session-location', () => null)
  const storedLocationCountryInternal = useState<Location | null>('session-location-country', () => null)

  const enabled = computed(() => {
    if (context.type.value === 'dynamic') {
      return false
    }

    return true
  })

  const storedLocation = computed(() => {
    if (!enabled.value) {
      return null
    }

    return storedLocationInternal.value
  })

  const storedLocationCountry = computed(() => {
    if (!enabled.value) {
      return null
    }

    return storedLocationCountryInternal.value
  })

  function getCountryLocationFromCode(countryCode: string): Location | null {
    if (!enabled.value) {
      return null
    }

    let country: Location | null = null

    locations.forEach((location) => {
      if (location.type !== 'country') {
        return
      }

      if (location.countryCode !== countryCode) {
        return
      }

      country = location
    })

    return country
  }

  function storeCountryInSession(loc: Location) {
    // pushQueryParam(null)
    if (!enabled) {
      storedLocationCountryInternal.value = null
      return
    }

    if (!loc.parentUUID || loc.type === 'country') {
      LocationSessionRepository.removeLocation()
      storedLocationInternal.value = null

      LocationSessionRepository.storeCountry(loc)
      storedLocationCountryInternal.value = loc
      return
    }

    const parent = locationsByUUID[loc.parentUUID]
    if (!parent) {
      storedLocationCountryInternal.value = null
      return
    }

    storeCountryInSession(parent)
  }

  function storeLocationInSession(loc: Location) {
    if (!enabled) {
      storedLocationInternal.value = null
      return
    }

    if (!loc.parentUUID || loc.type === 'community' || loc.type === 'region') {
      LocationSessionRepository.storeLocation(loc)
      storedLocationInternal.value = loc
      return
    }

    const parent = locationsByUUID[loc.parentUUID]
    if (!parent) {
      storedLocationInternal.value = null
      return
    }

    storeLocationInSession(parent)
  }

  function readFromSession() {
    if (!enabled) {
      storedLocationInternal.value = null
      return
    }

    storedLocationInternal.value = LocationSessionRepository.readLocation()
    storedLocationCountryInternal.value = LocationSessionRepository.readCountry()
  }

  function storeLocationAndCountry(currentLocationBasedOnURL: Location) {
    storeCountryInSession(currentLocationBasedOnURL)
    storeLocationInSession(currentLocationBasedOnURL)

    // Reset country=ES,PT parameter
    pushQueryParam(null)
  }

  function goToStoredCountryOrQueryCountry() {
    // useSelectorToQuery onMounted
    runOnMounted()

    const queryCountryCode = optionSelected.value.value
    if (queryCountryCode) {
      // Search country
      const country = getCountryLocationFromCode(queryCountryCode)
      if (country) {
        storeCountryInSession(country)
        return
      }
    }

    readFromSession()
    const storedCountry = storedLocationCountry.value

    if (storedCountry) {
      // En las genéricas, borramos la localización para quedarnos solo con el país.
      storeCountryInSession(storedCountry)
    }
  }

  return {
    enabled,

    storedLocationCountry,
    storedLocation,

    storeLocationAndCountry,

    storeLocationInSession,
    storeCountryInSession,

    goToStoredCountryOrQueryCountry,

    readFromSession,
  }
}
