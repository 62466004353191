<template>
  <div>
    <GeneralLoading />
    <GeneralNotificator />
    <GeneralLogger />

    <GeneralGA4Analitics />
    <GeneralAdBlockDetector />

    <GeneralKlaroConfigDialog />
    <GeneralConnectif />
    <GeneralAwin />

    <Sentry />

    <!-- TODO: Cambiar esto por un nuxt-page para que los layout vayan dentro de cada página y así poder pasarles parámetros -->
    <nuxt-layout />
  </div>
</template>

<script lang="ts">
import GeneralLoading from '~/core/components/singleton/GeneralLoading.vue'
import GeneralNotificator from '~/core/components/singleton/GeneralNotificator.vue'
import GeneralLogger from './core/components/singleton/GeneralLogger.vue'

import GeneralGA4Analitics from '~/core/components/singleton/GeneralGA4Analitics.vue'
import GeneralAdBlockDetector from './core/components/singleton/GeneralAdBlockDetector.vue'

import GeneralKlaroConfigDialog from './core/components/singleton/GeneralKlaroConfigDialog.vue'
import GeneralConnectif from './core/components/singleton/GeneralConnectif.vue'
import GeneralAwin from './core/components/singleton/GeneralAwin.vue'

import Sentry from '~/core/components/singleton/Sentry.vue'

import { useFetchProxy } from './core/composable/shared/useFetchProxy'
import { useSessionLocation } from './core/composable/shared/useSessionLocation'
import { useContext } from './core/composable/shared/useContext'

import type { Location } from './core/ts/entity/Location'
import config from './core/ts/server-config'

export default defineNuxtComponent({
  components: {
    GeneralLoading,
    GeneralNotificator,
    GeneralLogger,
    GeneralGA4Analitics,
    GeneralAdBlockDetector,
    GeneralKlaroConfigDialog,
    GeneralConnectif,
    GeneralAwin,
    Sentry,
  },
  async setup() {
    const route = useRoute()

    const context = useContext()

    onMounted(() => {
      if (currentLocationBasedOnURL) {
        sessionLocation.storeLocationAndCountry(currentLocationBasedOnURL)
        return
      }

      // No estamos en una landing con localización.
      sessionLocation.goToStoredCountryOrQueryCountry()
    })

    async function getCurrentLocation(): Promise<Location | null> {
      if (context.type.value === 'dynamic' || context.type.value === 'homes') {
        return null
      }

      if (config.generator.redirectionsEnabled) {
        return null
      }

      // TODO: useFetchProxy. Añadir ésto de la clave y la respuesta en objeto para todas las llamadas.
      const response = await useFetchProxy<{ ok: boolean, location: Location | null }>('/api/shared/get-location-for-uri', {
        method: 'post',
        body: { uri: route.path },
        key: `app-${route.path}`
      })

      return response.location
    }

    async function getAllLocations(): Promise<Array<Location>> {
      if (context.type.value === 'dynamic') {
        return []
      }

      if (config.generator.redirectionsEnabled) {
        return []
      }

      return await useFetchProxy<Array<Location>>('/api/locations/location-list-with-any-landing', {})
    }

    const currentLocationBasedOnURL = await getCurrentLocation()
    const allLocations = await getAllLocations()

    const sessionLocation = useSessionLocation(allLocations)

    return {
      allLocations,

      country: sessionLocation.storedLocationCountry,
    }
  },
})
</script>

<style lang="scss">
// Util SCSS clases
.util-snippet {
  @apply h-0;
  @apply opacity-0;
  display: none;
}

.only-mobile {
  @screen lg {
    @apply hidden;
  }
}

.only-desktop-block {
  @apply hidden;
  @screen lg {
    @apply block;
  }
}

.cm-body {
  display: none;
}

// .cn-decline, .cm-btn-decline {
//   display: none !important;
// }

.cm-btn-accept {
  // display: none;
  background-color: #557f9c !important;
  color: white;
}

.cm-btn-accept-all {
  background: #4d9907 !important;
  color: white;
}

.cm-powered-by a {
  display: hidden !important;
}

.cm-footer-buttons {
  justify-content: end !important;
}

</style>
