<template>
  <div class="iframe">
    <div class="iframe__breadcrumb">
      <Breadcrumb :override-url="breadcrumbs" />
    </div>
    <div
      v-if="isAuxIFrameDisplayed"
      :id="frameID"
      class="hidden"
    />
    <div :id="frameIDLoaded" />
  </div>
</template>

<script lang="ts">
import { BookingIFramePathBuilder } from '~/core/ts/legacy/helpers/BookingIframePathBuilder'
import { DocumentScriptLoader } from '~/core/ts/legacy/helpers/DocumentScriptLoader'

import Breadcrumb from '~/core/components/shared/Breadcrumb.vue'

import { useNavPopStateReload } from '~/core/composable/shared/useNavPopStateReload'

import CookiesRepository from '~/core/ts/repository/CookiesRepository'
import LoadingEventBus from '~/core/events/loading'

type WindowWithPlugins = Window & typeof globalThis & { UTBInsertSpaBooking?: any; jQuery: any };

definePageMeta({
  layout: 'checkout',
})

export default defineNuxtComponent({
  components: {
    Breadcrumb,
  },
  setup() {
    const frameID = 'utb-iframe-spa'
    const frameIDLoaded = 'utb-iframe-spa-loaded'

    const isAuxIFrameDisplayed = ref(true)
    function setIsAuxIFrameDisplayed(value: boolean) {
      isAuxIFrameDisplayed.value = value
    }

    const route = useRoute()

    const query = route.query

    const serviceBooking = {
      spaUUID: String(query.spaUUID),
      serviceUUID: String(query.serviceUUID),
      serviceRevisionUUID: String(query.serviceRevisionUUID),
      brandID: String(query.brandID),
      langCode: String(query.langCode || 'es'),
      buyVariant: 'booking',
      count: Number(query.count),
      isPackage: query.isPackage === 'true',
    }

    const runtimeConfig = useRuntimeConfig()
    const environment = runtimeConfig.public.environment || ''

    const iframePath = runtimeConfig.public.bookingIframePath || ''

    const insertSpaBooking = async (iframeID: string) => {
      const windowWithPlugins = window as WindowWithPlugins
      const { base, path } = new BookingIFramePathBuilder({
        serviceBooking: serviceBooking,
        cookies: CookiesRepository.getCookiesAsObject('klaro'),
        isProd: environment === 'production',
      }).build()

      new windowWithPlugins.UTBInsertSpaBooking({ base, path }).insertIFrameAndPReset(iframeID)
    }

    const tryToLoadIFrame = () => {
      new DocumentScriptLoader(document).load({
        url: iframePath,
        onLoad: async () => {
          await insertSpaBooking(frameIDLoaded)
          setIsAuxIFrameDisplayed(false)

          LoadingEventBus.hide()
        },
      })

      if (!(window as WindowWithPlugins).jQuery) {
        setTimeout(tryToLoadIFrame, 1000)
        LoadingEventBus.show()
      }
    }

    const scrollToTopWhenPurchaseClick = async () => {
      window.addEventListener('message', ({ data }) => {
        if (data.event === 'scrolltop') {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }
      })
    }

    useNavPopStateReload()

    onMounted(() => {
      LoadingEventBus.show()
      if (process.server) {
        return null
      }

      tryToLoadIFrame()
      scrollToTopWhenPurchaseClick()
    })

    const breadcrumbs = String(query.serviceUri)

    return {
      frameID,
      frameIDLoaded,
      isAuxIFrameDisplayed,
      setIsAuxIFrameDisplayed,
      breadcrumbs,
    }
  },
})
</script>

<style lang="scss" scoped>
.iframe {
  @apply mt-40p;
  min-height: 50vh;
  @screen sm {
    min-height: 70vh;
  }

  &__breadcrumb {
    @apply mx-auto;
    @screen lg {
      width: 1100px;
    }

    @screen sm {
      @apply mx-auto;
    }
  }
}
</style>
