<template>
  <div />
</template>

<script lang="ts">
import GA4EventManager from '~/core/ts/events/GA4EventManager'
import GA4AnaliticsEventBus from '~/core/events/ga4-analitics'
import CookiesRepository from '~/core/ts/repository/CookiesRepository'

export default defineNuxtComponent({
  setup() {
    const runtimeConf = useRuntimeConfig()

    function installGA4IfNeeded() {
      if (GA4EventManager.running) {
        return
      }

      console.warn('Google Analitics. Is not running. Installing...')
      GA4EventManager.key = runtimeConf.public.ga4Key
      GA4EventManager.keyConversion = runtimeConf.public.ga4ConversionKey
      GA4EventManager.startEventLoop()
      console.warn('Google Analitics status: ', GA4EventManager.running)
    }

    onMounted(() => {
      if (runtimeConf.public.isDev) {
        return
      }

      installGA4IfNeeded()

      const klaroUserConfig = CookiesRepository.getCookiesAsObject('klaro')
      const consent = !!klaroUserConfig['google-analytics']
      const consentStr = consent ? 'granted' : 'denied'
      console.warn('GA4 default consent: ', consentStr)
      GA4EventManager.pushEvent('consent.update', {
        'ad_storage': consentStr,
        'ad_user_data': consentStr,
        'ad_personalization': consentStr,
        'analytics_storage': consentStr
      })

      CookiesRepository.addCookieConsentCallback(() => {
        const klaroUserConfig = CookiesRepository.getCookiesAsObject('klaro')
        const consent = !!klaroUserConfig['google-analytics']

        if (consent) {
          console.warn('Enabling GA4 consent')
          GA4EventManager.pushEvent('consent.update', {
            'ad_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'analytics_storage': 'granted'
          })
        }
      })

      GA4AnaliticsEventBus.on((name: string, meta: Record<string, string>) => {
        installGA4IfNeeded()

        const klaroUserConfig = CookiesRepository.getCookiesAsObject('klaro')
        if (!klaroUserConfig['google-analytics']) {
          console.warn('User declined Google Analitics')

          // NOTE: Esto no puede ser muy legal.
          // return
        }

        GA4EventManager.pushEvent(name, meta)

        const blockers = CookiesRepository.getCookiesAsObject('blockers')
        if (blockers.enabled) {
          const { transaction_id, voucher_id } = meta
          if (!transaction_id) {
            return
          }

          const cookiesEnabled = !!klaroUserConfig['google-analytics']

          const adblockEvent = {
            name,
            purchase_id: transaction_id,
            voucher_id,

            cookies_accepted: cookiesEnabled,
            adblock_enabled: true,
          }

          GA4EventManager.pushEvent('event_with_adblock', adblockEvent)
        }
      })
    })

    return {}
  },
})
</script>
