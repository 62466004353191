<template>
  <div />
</template>

<script lang="ts">
import CookiesRepository from '~/core/ts/repository/CookiesRepository'

export default defineNuxtComponent({
  setup() {
    function initTracking() {
      const wind: any = window as any
      wind.AWIN = wind.AWIN || {}
      wind.AWIN.Tracking = wind.AWIN.Tracking || {}
      wind.AWIN.Tracking.Sale = {}

      const dwinScript = document.createElement('script')
      dwinScript.setAttribute('src', 'https://www.dwin1.com/106069.js')
      dwinScript.setAttribute('defer', 'defer')
      dwinScript.setAttribute('type', 'text/javascript')
      document.body.appendChild(dwinScript)
    }

    const route = useRoute()
    onMounted(() => {
      const awc = route.query['awc'] as string || null
      if (awc) {
        CookiesRepository.addCookie('awc', awc)
      }

      initTracking()
    })

    return {
    }
  },
})
</script>
